import "../styles/products.css";
import suspensionImg from "../assets/suspention-kit.jpeg";
import alternatorImg from "../assets/alternator.jpeg";
import generatorImg from "../assets/generator1.jpeg";
import winchImg from "../assets/winch.jpeg";
import jackImg from "../assets/jack.jpg";
import compressorImg from "../assets/compressor.jpeg";
import chargerImg from "../assets/charger.jpg";
import pumpImg from "../assets/pump.jpeg";
import refregImg from "../assets/refreg.jpeg";
import linerImg from "../assets/liner.jpeg";
import weldingImg from "../assets/welding.jpg";
import motorImg from "../assets/motor.jpeg";
import pressImg from "../assets/press.jpeg";
import turboImg from "../assets/turbo.jpeg";
import wrenchImg from "../assets/wrench.jpg";
import beltImg from "../assets/belt.jpeg";
import cylinderImg from "../assets/cylinder.jpg";
import acImg from "../assets/ac.jpeg";
import dustImg from "../assets/dust.jpeg";
import pressImg2 from "../assets/press.jpg";
import exhaustImg from "../assets/exhaust.jpeg";
import hammerImg from "../assets/hammer.jpeg";
import fanImg from "../assets/fan.jpeg";
import grinderImg from "../assets/grinder.jpeg";
import powerImg from "../assets/power.jpeg";
import vacumImg from "../assets/vacum.jpeg";
import clutchImg from "../assets/clutch.jpeg";
import wrenchImg2 from "../assets/wrench2.jpeg";
import chillerImg from "../assets/chiller.jpg";
import hoistImg from "../assets/hoist.jpg";
import valveImg from "../assets/valve.jpeg";

const products = [
  {
    name: "Industrial Generator",
    brand: "PowerMax",
    image: generatorImg,
    description:
      "A heavy-duty generator capable of powering large industrial machinery and equipment.",
  },
  {
    name: "High-Performance Alternator",
    brand: "TechVolt",
    image: alternatorImg,
    description:
      "An alternator designed to provide consistent power output for high-performance vehicles.",
  },
  {
    name: "Heavy-Duty Suspension Kit",
    brand: "ToughRide",
    image: suspensionImg,
    description:
      "Upgrade your vehicle's suspension with this heavy-duty kit, designed for off-road adventures.",
  },
  {
    name: "Industrial Air Compressor",
    brand: "AirWorks",
    image: compressorImg,
    description:
      "A powerful air compressor suitable for industrial applications, providing reliable air supply.",
  },
  {
    name: "Hydraulic Jack",
    brand: "MightyLift",
    image: jackImg,
    description:
      "This hydraulic jack is perfect for lifting heavy loads with ease, essential for automotive workshops.",
  },
  {
    name: "Heavy-Duty Winch",
    brand: "WinchMaster",
    image: winchImg,
    description:
      "Pull your vehicle out of tough situations with this heavy-duty winch, built for rugged terrain.",
  },
  {
    name: "Commercial Refrigeration Unit",
    brand: "FrostCool",
    image: refregImg,
    description:
      "Keep your perishable goods fresh with this commercial-grade refrigeration unit, suitable for restaurants and supermarkets.",
  },
  {
    name: "Industrial Water Pump",
    brand: "AquaFlow",
    image: pumpImg,
    description:
      "This industrial water pump is designed for heavy-duty use, capable of handling large volumes of water efficiently.",
  },
  {
    name: "Heavy-Duty Battery Charger",
    brand: "ChargePro",
    image: chargerImg,
    description:
      "Charge your heavy-duty batteries quickly and efficiently with this high-performance battery charger.",
  },
  {
    name: "High-Torque Starter Motor",
    brand: "TorqueKing",
    image: motorImg,
    description:
      "Start your engine with confidence using this high-torque starter motor, perfect for heavy-duty vehicles.",
  },
  {
    name: "Industrial Welding Machine",
    brand: "WeldTech",
    image: weldingImg,
    description:
      "Join metals with precision using this industrial welding machine, suitable for heavy fabrication work.",
  },
  {
    name: "Heavy-Duty Truck Bed Liner",
    brand: "ArmorLiner",
    image: linerImg,
    description:
      "Protect your truck bed from scratches and dents with this heavy-duty bed liner, designed for rugged use.",
  },
  {
    name: "Industrial Drill Press",
    brand: "DrillMaster",
    image: pressImg,
    description:
      "Drill precise holes in metal and wood with this industrial-grade drill press, built for accuracy and durability.",
  },
  {
    name: "High-Performance Turbocharger",
    brand: "TurboBoost",
    image: turboImg,
    description:
      "Boost your engine's performance with this high-performance turbocharger, designed for maximum power output.",
  },
  {
    name: "Heavy-Duty Impact Wrench",
    brand: "ImpactForce",
    image: wrenchImg,
    description:
      "Tighten and loosen nuts and bolts with ease using this heavy-duty impact wrench, perfect for automotive and industrial applications.",
  },
  {
    name: "Industrial Air Conditioner",
    brand: "CoolBreeze",
    image: acImg,
    description:
      "Maintain a comfortable working environment with this industrial air conditioner, suitable for large warehouses and factories.",
  },
  {
    name: "Heavy-Duty Hydraulic Cylinder",
    brand: "HydraForce",
    image: cylinderImg,
    description:
      "Power your hydraulic machinery with this heavy-duty cylinder, designed for reliable performance under pressure.",
  },
  {
    name: "Industrial Conveyor Belt",
    brand: "ConveyTech",
    image: beltImg,
    description:
      "Transport materials efficiently with this industrial conveyor belt, designed for heavy loads and continuous operation.",
  },
  {
    name: "High-Performance Exhaust System",
    brand: "ExhaustPro",
    image: exhaustImg,
    description:
      "Increase horsepower and improve exhaust flow with this high-performance exhaust system, engineered for maximum efficiency.",
  },
  {
    name: "Heavy-Duty Hydraulic Press",
    brand: "PressMaster",
    image: pressImg2,
    description:
      "Form metal and shape materials with precision using this heavy-duty hydraulic press, essential for metalworking shops.",
  },
  {
    name: "Industrial Dust Collector",
    brand: "DustTech",
    image: dustImg,
    description:
      "Keep your workspace clean and free of airborne particles with this industrial dust collector, suitable for woodworking and metalworking applications.",
  },
  {
    name: "Heavy-Duty Impact Hammer",
    brand: "HammerForce",

    image: hammerImg,
    description:
      "Break through tough materials with ease using this heavy-duty impact hammer, perfect for demolition and construction work.",
  },
  {
    name: "Industrial Fan",
    brand: "AirFlow",
    image: fanImg,
    description:
      "Improve air circulation in your facility with this industrial fan, designed for cooling and ventilation in large spaces.",
  },
  {
    name: "Heavy-Duty Angle Grinder",
    brand: "GrindMaster",
    image: grinderImg,
    description:
      "Cut, grind, and polish metal with precision using this heavy-duty angle grinder, essential for metal fabrication and construction projects.",
  },
  {
    name: "Industrial Water Chiller",
    brand: "ChillTech",
    image: chillerImg,
    description:
      "Maintain optimal temperatures in your industrial processes with this water chiller, designed for cooling heavy machinery and equipment.",
  },
  {
    name: "Heavy-Duty Chain Hoist",
    brand: "HoistMaster",
    image: hoistImg,
    description:
      "Lift and move heavy loads with ease using this heavy-duty chain hoist, perfect for industrial and construction applications.",
  },
  {
    name: "High-Performance Racing Clutch",
    brand: "ClutchTech",
    image: clutchImg,
    description:
      "Upgrade your vehicle's performance with this high-performance racing clutch, designed for maximum power transfer and durability.",
  },
  {
    name: "Heavy-Duty Hydraulic Power Unit",
    brand: "HydraPower",
    image: powerImg,
    description:
      "Power your hydraulic machinery with this heavy-duty power unit, capable of providing reliable hydraulic power in demanding applications.",
  },
  {
    name: "Industrial Vacuum Cleaner",
    brand: "VacMaster",
    image: vacumImg,
    description:
      "Keep your workspace clean and dust-free with this industrial vacuum cleaner, designed for heavy-duty cleaning tasks.",
  },
  {
    name: "Heavy-Duty Pneumatic Impact Wrench",
    brand: "PneuForce",
    image: wrenchImg2,
    description:
      "Tighten and loosen bolts with speed and precision using this heavy-duty pneumatic impact wrench, perfect for automotive and industrial applications.",
  },
  {
    name: "Industrial Hydraulic Valve",
    brand: "HydraValve",
    image: valveImg,
    description:
      "Control hydraulic fluid flow with precision using this industrial hydraulic valve, essential for hydraulic systems in heavy machinery.",
  },
];

function Products() {
  return (
    <>
      <h1 className="text-center mt-5 text-decoration-underline">
        Our Products
      </h1>
      <div className="products-container d-flex justify-content-center flex-wrap gap-3">
        {products.map((product) => (
          <div
            className="card"
            style={{ width: "23rem", height: "27rem" }}
            key={product.name}
          >
            <img
              src={product.image}
              className="card-img-top"
              alt={product.name}
              style={{ height: "200px", width: "100%", objectFit: "cover" }} // Adjust height as needed
            />
            <div className="card-body" style={{ height: "25 0px" }}>
              {" "}
              {/* Set a fixed height for card body */}
              <h5 className="card-title">{product.name}</h5>
              <p className="card-text" style={{ marginBottom: "10px" }}>
                {product.description}
              </p>
              <a
                href="/contact"
                className="btn btn-primary"
                style={{ position: "absolute", bottom: "10px" }}
              >
                {" "}
                {/* Position button at the bottom */}
                For more details
              </a>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Products;
