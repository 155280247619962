import Img1 from "../assets/Cla_Lighting.jpg";
import Img2 from "../assets/Hunter_Pacific.jpg";
import Img3 from "../assets/Prestige_fans.jpg";
import Img4 from "../assets/Superlux_Lighting.png";
import Img5 from "../assets/Ventair_Air_Movement.png";
import Img6 from "../assets/Verbatim_Lighting.png";

import "../styles/partners.css";

function OurPartners() {
  return (
    <>
      <div className="mb-5">
        <div className="mini-footer d-flex flex-column align-items-center justify-content-center">
          <h2>Contact us to get a price!</h2>
          <button className="btn btn-warning mt-3">Contact Us</button>
        </div>
      </div>
      <div className="mt-5 mb-5">
        <h4 className="text-center text-decoration-underline">
          Brands we work with
        </h4>
        <div className="partners d-flex justify-content-center mt-5 mb-5 partner-container">
          <img src={Img1} alt="image" />
          <img src={Img2} alt="image" />
          <img src={Img3} alt="image" />
          <img src={Img4} alt="image" />
          <img src={Img5} alt="image" />
          <img src={Img6} alt="image" />
        </div>
      </div>
    </>
  );
}

export default OurPartners;
