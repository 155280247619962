import "../styles/contact.css";

function Contact() {
  return (
    <>
      <div className="contact-container">
        <h1 className="text-center mt-5">
          Contact Us for pricing and delivery
        </h1>
        <h6 className="text-center mb-5">
          *Please mention the product you want
        </h6>
        <form className="row g-3">
          <div className="col-md-6">
            <label for="inputEmail4" className="form-label">
              Name
            </label>
            <input type="text" className="form-control" id="inputEmail4" />
          </div>
          <div className="col-md-6">
            <label for="inputPassword4" className="form-label">
              Email
            </label>
            <input type="email" className="form-control" id="inputPassword4" />
          </div>
          <div className="col-12">
            <label for="inputAddress" className="form-label">
              Address
            </label>
            <input type="text" className="form-control" id="inputAddress" />
          </div>
          <div className="col-md-6">
            <label for="inputCity" className="form-label">
              City
            </label>
            <input type="text" className="form-control" id="inputCity" />
          </div>
          <div className="col-md-4">
            <label for="inputState" className="form-label">
              State
            </label>
            <select id="inputState" className="form-select">
              <option selected>Choose...</option>
              <option>NSW</option>
              <option>QLD</option>
              <option>VIC</option>
              <option>WA</option>
              <option>SA</option>
              <option>TZ</option>
            </select>
          </div>
          <div className="col-md-2">
            <label for="inputZip" className="form-label">
              Zip
            </label>
            <input type="text" className="form-control" id="inputZip" />
          </div>
          <div className="form-floating">
            <textarea
              className="form-control"
              placeholder="Leave a comment here"
              id="floatingTextarea2"
              style={{ height: "100px" }}
            ></textarea>
            <label for="floatingTextarea2">Your Message</label>
          </div>
          <div className="col-12">
            <button type="submit" className="btn btn-warning mb-5">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default Contact;
