import Cards from "../components/Cards";
import OurPartners from "../components/OurPartners";
import "../styles/home.css";

function Home() {
  return (
    <>
      <div className="home-bg d-flex justify-content-center">
        <div className="home-left">
          Power Up Your Space: Explore Our Range of Electrical Products!
        </div>
        <div className="home-right"></div>
      </div>
      <Cards />
      <OurPartners />
    </>
  );
}

export default Home;
